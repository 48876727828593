<template>
  <div class="container-lg kap-container">
    <DashboardNavigation ref="dashboardNavigation" @filters-changed="handleFiltersChanged"></DashboardNavigation>
    <div class="content mt-2">
      <h3 class="page-title company-color">{{ pageTitle }}</h3>
      <br/>
      <DataTable
          :lazy="true" :value="submissions" :key="list"
          :class="[striped]" ref="dt"
          :paginator="true" :totalRecords="totalRecords" :first="0" :rows="10" @page="onPage($event)" @row-click="rowClick($event)"
          dataKey="uuid" :rowHover="submissions && submissions.length > 0" :loading="loading"
          :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="stack" :alwaysShowPaginator="false"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        <template #empty>
          No submissions found.
        </template>
        <template #loading>
          Loading submissions data. Please wait...
        </template>
        <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field">
          <template #editor="slotProps">
            {{ slotProps.submissions[slotProps.column.field] }}
          </template>
        </Column>
      </DataTable>
      <CommissionNote v-if="commissionExist" ref="commissionNote"></CommissionNote>
    </div>
  </div>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DashboardNavigation from "../DashboardNavigation";
import apiService from "@/plugins/api";
import {map, isEmpty} from "underscore"
import utils from "../../utils/";
import CommissionNote from "../Note/CommissionNote";
import { getSecret } from '../../services/aws.service';


export default {
  name: 'Submissions',
  data() {
    const statusSelected = this.isStatusSelected(this.$route.query.status);
    let pageTitle = statusSelected ? `Submissions in "${this.$route.query.status}" status` : 'All Submissions';
    if (this.$route.query.term) {
      pageTitle = `Search results for "${this.$route.query.term}"${'' + (statusSelected ? ' in status "' + this.$route.query.status + '"' : '')}`;
    }

    const orderBy = 'created_at';
    if (this.$route.query.displayFundingOnly) {
      pageTitle = 'Commission Accrued*';
    }

    return {
      striped: 'p-datatable-striped p-datatable-sm',
      requestParameters: {
        pagination: {
          limit: 10,
          page: 1,
        },
        fromDate: '',
        toDate: '',
        kapitus_application_status: this.$route.query.status,
        search_term: this.$route.query.term,
        order_by: orderBy,
        direction: 'desc',
      },
      submissions: null,
      totalRecords: 0,
      loading: true,
      isFetching: false,
      columns: [],
      defaultOrder: '',
      list: 0,
      pageTitle,
      commissionExist: false
    }
  },
  created() {

    let columns = [
      {field: 'business_name', header: 'Business Name'},
      {field: 'owners_name', header: 'Owner Name'},
      {field: 'created_at', header: 'Submitted Date'},
      {field: 'requested_amount', header: 'Requested Amount'},
      {field: 'status', header: 'Status'},
    ];

    if (this.$route.query.displayFundingOnly ||
        (typeof this.$route.query.status !== 'undefined' && this.$route.query.status === 'Funded')
    ) {
      columns = [
        {field: 'business_name', header: 'Business name'},
        {field: 'created_at', header: 'Submitted Date'},
        {field: 'funded_date', header: 'Funded Date'},
        {field: 'funded_amount', header: 'Funded Amount'},
        {field: 'financing_provider', header: 'Financing Provider'},
        {field: 'commission_amount', header: 'Commission Accrued*'},
      ];
      this.commissionExist = true;
    }
    this.columns = columns;
    document.title = this.pageTitle;
  },
  mounted() {
    this.loadData();
    this.loadStatusMapping();
  },
  components: {
    Column,
    DataTable,
    DashboardNavigation,
    CommissionNote
  },
  methods: {
    async loadStatusMapping() {
      await utils.loadSubmissionStatusesMapping();
    },
    async loadData() {
      if (this.isFetching) {
        return;
      }
      this.loading = true;
      this.isFetching = true;
      try {
        const resp = await apiService().getSubmissions(this.requestParameters);
        this.submissions = await this.transform(resp.data);
        this.totalRecords = resp.options.records;
      } catch (e) {
        console.error(e);
      } finally {
        this.isFetching = false;
        this.loading = false;
      }
    },
    onPage(event) {
      this.requestParameters.pagination.page = event.page + 1;
      this.requestParameters.pagination.limit = event.rows;
      this.loadData();
    },
    rowClick(event) {
      this.$router.push({name: 'ViewSubmission', params: {"submission_id": event.data.uuid}});
    },
    handleFiltersChanged(filters) {
      this.requestParameters.pagination.page = 1;
      this.requestParameters.fromDate = filters.dateStart;
      this.requestParameters.toDate = filters.dateEnd;
      this.loadData();
    },
    formatDate(value) {
      if (!isEmpty(value)) {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
      return '';
    },
    formatCurrency(value) {
      const providedValue = !value ? 0 : value;
      return providedValue.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    },

    async statusesTransformation(status) {
      const submission_statuses_mapping = await getSecret('pex/partner-portal-submission-statuses');

      if (typeof submission_statuses_mapping[status.toLowerCase()] !== 'undefined') {
        return submission_statuses_mapping[status.toLowerCase()];
      } else {
        return '';
      }
    },
    async transform(submissions) {
      return Promise.all(
        map(submissions, async (submission) => {
          const submission_status = await this.statusesTransformation(submission.kapitus_application_status);
          return {
            uuid: submission.uuid,
            created_at: utils.utcToLocalDate(submission.created_at),
            business_name: submission.business != null ? submission.business.name : '',
            owners_name: this.getOwnerNames(submission).join(', '),
            status: submission_status,
            requested_amount: this.formatCurrency(submission.requested_funding),
            funded_amount: this.formatCurrency(submission.funded_amount),
            funded_date: this.formatDate(submission.funded_date),
            financing_provider: submission.financing_provider,
            commission_amount: this.formatCurrency(submission.commission_amount),
          };
        })
      );
    },
    getOwnerNames(submission) {
      if (isEmpty(submission.owners)) {
        return [];
      }

      return map(submission.owners, (owner) => {
        return `${owner.first_name} ${owner.last_name}`;
      });
    },
    isStatusSelected(status) {
      return !isEmpty(status) && status !== 'All';
    }
  }
}
</script>

<style>

.p-datatable {
  font-family: 'Lato', sans-serif;
  font-size: .8rem;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #e6ebef;
}

.p-datatable .p-datatable-header {
  background: #ffffff;
  border: none;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 1rem 0.5rem;
}

.reset {
  text-decoration: underline;
  cursor: pointer;
}

.p-datatable-tbody td {
  word-wrap: break-word;
  cursor: pointer;
}
</style>
